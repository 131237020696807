@import "styles/variables";

.section {
  border: 0 solid;
  border-bottom-width: 1px;
  border-image: linear-gradient(to right, #0E1418, $primary-color, #0E1418) 1 0;
  padding-bottom: 60px;

  &-header {
    margin-bottom: 40px;
  }

  &-title {
    font-size: 28px;
    line-height: 36px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  &-content {
  }
}
