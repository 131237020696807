.input {
  display: inline-block;
  background-color: transparent;
  font-size: 24px;
  line-height: 68px;
  text-align: center;
  border: 0;
  border-bottom: 1px solid #32373A;
  width: 100%;
  height: 68px;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
  }

  &-number {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}
