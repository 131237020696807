@font-face {
  font-family: 'Porsche Next TT';
  src: local('PorscheNextTT-Bold'), url('PorscheNextTT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Porsche Next TT';
  src: local('PorscheNextTT-BoldItalic'), url('PorscheNextTT-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Porsche Next TT';
  src: local('PorscheNextTT-Regular'), url('PorscheNextTT-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next TT';
  src: local('PorscheNextTT-Italic'), url('PorscheNextTT-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
