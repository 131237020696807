.manager {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-avatar {
    margin-right: 20px;
  }

  &-info {
  }

  &-name {
    font-size: 18px;
    line-height: 24px;
  }

  &-position {
    color: #7B8B9A;
    font-size: 12px;
    line-height: 16px;
  }

  &-connect {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
