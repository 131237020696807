.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(14, 20, 24, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;

  &-is-visible {
    opacity: 1;
    pointer-events: auto;
  }

  &-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }


  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px;
    padding: 32px 16px;
    background-color: #21262A;

    @media(min-width: 768px) {
      max-width: 400px;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;

    @media(min-width: 768px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &-img {
    margin-bottom: 20px;
  }
}
