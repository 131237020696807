@import "styles/variables";

.order-info {
  display: flex;
  justify-content: space-between;
  margin: auto;
  
  @media (min-width: 768px) {
    max-width: 380px;
  }

  &-titles {}

  &-values {}

  &-title {
    color: #707070;
  }

  &-value {
  }

  &-number, &-number-value {
    font-size: 24px;
    line-height: 24px;
  }

  &-number-value {
    color: $primary-color;
  }

  &-status-value {
    color: #59E096;
  }
}
