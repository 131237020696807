@import "styles/variables";

.layout-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 86px;
  border: 0 solid;
  border-bottom-width: 1px;
  border-image: linear-gradient(to right, #0E1418, $primary-color, #0E1418) 1 0;

  @media (min-width: 768px) {
    height: 100px;
  }
}
