@import "assets/fonts/PorscheNextTT/style";
@import "styles/variables";


html, body, #root {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body.verra-app {
  color: #ffffff;
  font-family: 'Porsche Next TT';
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;
  background: rgb(14, 20, 24);
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;

  &:focus-visible {
    outline: 0;
  }
}


* {
  -webkit-tap-highlight-color: transparent;
}

.offer-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s;

    &-active {
      background-color: $primary-color;
    }
  }
}

.swiper-button-prev, .swiper-button-next {
  color: $primary-color !important;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.swiper-button-prev {
  left: 40px !important;
}

.swiper-button-next {
  right: 40px !important;
}

body.verra-app {
  .ant-picker-panel-container {
    background: #192229;
    border: 1px solid #4D5155;

    .ant-picker-panel {
      border: none;
    }

    .ant-picker-header {
      border-bottom: 1px solid #4D5155;
    }

    .ant-picker-cell {
      color: #848484;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner ,
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
      background: #fff;
      color: #192229;
    }

    .ant-picker-header, .ant-picker-header > button,
    .ant-picker-content th, .ant-picker-cell-in-view, .ant-picker-today-btn {
      color: #fff;
    }

    .ant-picker-cell-disabled::before {
      background: rgba(0, 0, 0, 0.25);
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid $primary-color;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:hover:before {
      border: 1px solid #fff;
    }

    .ant-picker-footer {
      border-top: 1px solid #4D5155;
      border-bottom: none;
    }
  }

  @media (max-width: 768px) {
    .ant-picker-dropdown {
      left: auto !important;
      right: 15px !important;
    }
  }
}
