@import "styles/variables";

.button {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  border: 0;
  width: 100%;
  height: 48px;
  transition: 0.3s;
  text-decoration: none;

  @media(min-width: 768px) {
    max-width: 344px;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &-default {
    background-color: $primary-color;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:active {
      background-color: darken($primary-color, 15%);
    }
  }

  &-secondary {
    background-color: #7B8B9A;

    &:hover {
      background-color: #6b7985;
    }
  }

  &-primary {
    background-color: #59E096;

    &:hover {
      background-color: #47b076;
    }
  }

  &-ghost {
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparent;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 1);
    }
  }

  &-modal {
    margin: 0 5px;
    width: 160px;
  }
}
