.layout-content {

  a {
    color: inherit;
    transition: 0.3s;

    &:hover {
      color: #FFFFFF;
    }
  }

  @media (min-width: 768px) {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 0 100px;
  }
}
