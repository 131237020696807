.offer {
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  height: 250px;

  @media (min-width: 768px) {
    height: 576px;
    width: 100%;
  }

  &-image {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05);
      background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
      pointer-events: none;
      z-index: 0;
    }

    > img {
      width: 100vw;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    position: absolute;
    left: 15px;
    bottom: 20px;
    padding-left: 22px;
    z-index: 1;

    @media (min-width: 768px) {
      left: 140px;
      bottom: 75px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 7px;
      height: 14px;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='14' viewBox='0 0 7 14'%3E%3Cpath d='M1,14a1,1,0,0,1-.768-1.64L4.707,6.989.392,1.627A1,1,0,0,1,1.951.373l4.828,6A1,1,0,0,1,6.768,7.64l-5,6A1,1,0,0,1,1,14' fill='%23c00'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }

  &-title {
    color: #FFFFFF;
    font-size: 22px;
    line-height: 33px;

    @media (min-width: 768px) {
      margin-bottom: 10px;
      font-size: 36px;
    }
  }

  &-description {
    color: #FFFFFF;
    font-size: 12px;
    line-height: 13px;
    max-width: 245px;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
