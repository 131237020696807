@import "styles/variables";

.login {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex: auto;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &-input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: initial;
    }
  }

  &-error {
    color: $primary-color;
    font-size: 14px;
    text-align: center;
  }
}
