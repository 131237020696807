.space {
  display: flex;

  &-vertical {
    width: 100%;
    flex-direction: column;
  }

  &-horizontal {
  }
}
