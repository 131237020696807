.calendar {


  &-title {
    font-size: 28px;
    text-align: center;

    @media(min-width: 768px) {
      font-size: 20px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 768px) {
      width: 344px;
      margin: auto;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      width: 16px;
      min-width: 16px;
    }

    &-name {
      margin-left: 10px;
    }
  }
}
