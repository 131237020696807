.greeting-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 295px;
  margin: 0 auto;
  padding: 120px 0 40px;
  text-align: center;
  flex: auto;

  @media (min-width: 768px) {
    max-width: 496px;
    padding: 160px 0 60px;
  }

  .text-blocks {
    text-align: left;
    margin-bottom: 48px;
    transition: 0.4s;

    @media (min-width: 768px) {
      margin-bottom: 125px;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 46px;
    }
  }

  p {

  }

  .button {

  }
}
