.divider {
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.2);

  &-horizontal {
    display: inline-flex;
    clear: both;
    width: 1px;
  }

  &-vertical {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    height: 1px;
  }
}
