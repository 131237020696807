@import 'styles/variables';

.collapse-panel {
  &-header {
    display: flex;
    align-items: center;
    margin: 15px 0 15px 15px;
    //overflow-x: hidden;
    cursor: pointer;

    @media (min-width: 768px) {
      margin: 30px 0 30px 0;
    }
  }

  &-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid $primary-color;
    border-radius: 50%;
    margin-right: 15px;
  }

  &-title {
    font-size: 18px;
    line-height: 22px;
    margin-right: auto;
  }

  &-arrow {
    min-width: 45px;
    min-height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='7' viewBox='0 0 14 7'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M13,7a1,1,0,0,1-.64-.232L6.989,2.292,1.627,6.607A1,1,0,1,1,.374,5.049l6-4.828A1,1,0,0,1,7.641.232l6,5A1,1,0,0,1,13,7' transform='translate(5 8)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-5 -8)'%3E%3Cpath d='M13,7a1,1,0,0,1-.64-.232L6.989,2.292,1.627,6.607A1,1,0,1,1,.374,5.049l6-4.828A1,1,0,0,1,7.641.232l6,5A1,1,0,0,1,13,7' transform='translate(5 8)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 14px 7px;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(-180deg);
    transition: transform 0.3s;

    @media (min-width: 768px) {
      min-width: 25px;
    }
  }

  &-wrapper {
    max-height: 0;
    overflow: hidden;
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  &-content {
    padding: 20px 15px;
    background-color: #21262A;
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;

    @media (min-width: 768px) {
      padding: 0 0 30px 0;
    }
  }

  &-active {}

  &-active &-arrow {
    transform: rotate(0);
  }

  &-active &-wrapper {
    max-height: 1000rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
  }

  &-active &-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
  }
}
