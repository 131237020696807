@import "styles/variables";

.recommendation {
  $self: &;

  @media (min-width: 768px) {
    padding: 0 55px;
  }

  &-content {
    @media (min-width: 768px) {
      max-width: 450px;
      margin: -44px 0px 0px 210px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      justify-content: left;
    }
  }

  &-title {
    &:before {
      content: '';
      display: inline-flex;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #FFFFFF;
      margin-right: 10px;
    }

    &-default:before {
      background-color: #FFFFFF;
    }

    &-processing:before {
      background-color: #F6AB07;
    }

    &-success:before {
      background-color: #59E096;
    }

    &-error:before {
      background-color: $primary-color;
    }
  }

  &-extra {
    @media (min-width: 768px) {
      margin-left: 60px;
    }
  }

  &-text {
    margin-bottom: 20px;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: transparent;
      border: none;
      height: auto;
      color: #ffffff;
      white-space: unset;
      line-height: 1;
      padding: 4px 15px;
      margin: 0px 6px;

      &:hover, &:active, &:focus {
        color: #fff;
        border: none;
        background: transparent;
      }

      img {
        margin-bottom: 16px;
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.2);;
    border-radius: 4px;
    padding: 30px;
    line-height: 1;

    .plan-date {
      font-size: 20px;
    }

    &.planned {
      justify-content: space-between;
      //border-color: #F6AB07;

      .plan-date {
        color: $warning-color;
      }

      .btn-to-calendar {
        color: $warning-color;
        line-height: 1;
      }
    }

    &.declined {
      //border-color: $primary-color;

      .plan-date {
        color: $error-color;
      }
    }

    &.today {
      //border-color: #59E096;

      .plan-date {
        color: $success-color;
      }
    }

    &.new {
      //border-color: #4D5155;
    }

    .status-title {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .button {
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-left: 210px;
      margin-top: 30px;
    }
  }
}

